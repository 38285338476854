<template>
  <OverviewWrapper />
</template>

<script>
import OverviewWrapper from '@/components/overview/OverviewWrapper'

export default {
  name: 'Overview',
  components: {
    OverviewWrapper
  }
}
</script>
