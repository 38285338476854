<template>
  <v-container
    fluid
  >
    <NewsFlashOverview
      class="mt-4"
    />

    <app-api-call
      class="mt-3"
      :call-result="callResult"
    />

    <basic-service-required
      v-if="callResult.finished && susrRkuvCredentials && susrRkuvCredentials.enabled === false"
      title="$l.overview.susrRkuvSourceNotSet"
      dclass="info1--text greyee text-caption mt-6"
      dstyle="border: 1px solid #ccc !important; border-radius: 0.3em;"
      push-to="/profile"
      label="$l.paths.profile"
    />

    <basic-card
      title="$l.overview.services"
      class="mt-11 success2 white"
      text-offset="-2"
    >
      <OverviewServices
        :key="servicesKey"
        @open-dialog="openActivateWithCouponDialog"
      />
    </basic-card>

    <ActivateWithCoupon
      :dialog-data="dialogData"
      @close-dialog="closeActivateWithCouponDialog()"
    />
  </v-container>
</template>

<script>
import NewsFlashOverview from './NewsFlashOverview.vue'
import OverviewServices from './OverviewServices.vue'
import ActivateWithCoupon from './ActivateWithCoupon.vue'

export default {
  name: 'OverviewWrapper',
  components: {
    NewsFlashOverview,
    OverviewServices,
    ActivateWithCoupon
  },
  data () {
    return {
      callResult: { finished: false, authorized: false, error: null, info: null },
      dialogData: { open: false },
      servicesKey: Date.now(),
      susrRkuvCredentials: {}
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.callResult.finished = false
      this.$xapi.get('endpointsUserProfile/susrRkuvCredentials')
        .then(r => {
          this.susrRkuvCredentials = r.data
          if (this.susrRkuvCredentials && this.susrRkuvCredentials.enabled === true) {
            this.$xapi.get('settings/rkuv/testAccessibility')
              .then(r => {
                this.callResult.info = this.$i18n.t('$l.overview.susrRkuvSourceSetAndActive')
                this.callResult.finished = true
              })
              .catch(e => {
                this.callResult.error = this.$i18n.t('$l.error.rkuvApiNotAvailable')
                this.callResult.finished = true
              })
          } else {
            this.callResult.finished = true
          }
        })
        .catch(e => {
          this.callResult.error = this.$i18n.t('$l.error.profileSettingsVerification')
          this.callResult.finished = true
        })
    },
    openActivateWithCouponDialog (activationData) {
      this.dialogData.open = true
      this.dialogData.activationData = activationData
    },
    closeActivateWithCouponDialog () {
      this.servicesKey = Date.now()
      this.dialogData = { open: false }
    }
  }
}
</script>
