<template>
  <basic-dialog
    v-model="dialogData.open"
    title="$l.overview.inputCoupon"
    :loading-save-button="sendingRequest"
    save-button
    save-button-label="$l.app.send"
    @close-dialog="closeDialog()"
    @save-dialog="sendRequest()"
  >
    <app-api-call
      class="ma-0 pa-0 mx-5"
      :call-result="callResult"
    />

    <v-alert
      outlined
      class="ma-5 pa-3 info1--text"
    >
      <v-alert
        v-if="dialogData.activationData && !dialogData.activationData.suspended"
        color="error--text ma-0 pa-0"
      >
        {{ '$l.overview.warningEnterCoupon' | translate }}
      </v-alert>
      <v-text-field
        v-model="dialogData.couponCode"
        :label="'$l.overview.enterCoupon' | translate"
        maxlength="11"
        counter
        :error-messages="($v.dialogData.couponCode.$dirty && !$v.dialogData.couponCode.required) ? $i18n.t('$l.app.required') : ''"
        @input="$v.dialogData.couponCode.$touch()"
        @blur="$v.dialogData.couponCode.$touch()"
      />
      <v-card-text class="body-2 ma-0 pa-0 mt-2">
        {{ '$l.overview.enterCouponInfo' | translate }}
      </v-card-text>
    </v-alert>
  </basic-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ActivateWithCoupon',
  props: {
    dialogData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      callResult: { finished: true, authorized: false, error: null, info: null },
      sendingRequest: false
    }
  },
  validations: {
    dialogData: {
      couponCode: { required }
    }
  },
  methods: {
    closeDialog () {
      this.callResult.error = null
      this.callResult.info = null
      this.$v.$reset()
      this.$emit('close-dialog')
    },
    sendRequest () {
      this.callResult.error = null
      this.callResult.info = null
      this.sendingRequest = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.sendingRequest = false
        return
      }
      this.$xapi.post('endpointsCoupon/activateCoupon', this.dialogData.couponCode)
        .then(r => {
          this.$v.$reset()
          this.callResult.info = this.$i18n.t('$l.info.couponHasBeenActivated')
          setTimeout(() => {
            this.callResult.info = null
            this.sendingRequest = false
            this.$emit('close-dialog')
          }, 2000)
        })
        .catch(e => {
          if (e.response.status === 400) {
            this.callResult.error = this.$i18n.t('$l.error.noSuchCoupon')
          } else if (e.response.status === 403) {
            if (e.response.statusText === 'alreadyUsed') {
              this.callResult.error = this.$i18n.t('$l.error.couponAlreadyUsed')
            } else if (e.response.statusText === 'limitExceeded') {
              this.callResult.error = this.$i18n.t('$l.error.couponLimitExceeded')
            } else if (e.response.statusText.includes('mustBeSameMerchant')) {
              const thisCodeMerchant = e.response.statusText.split('|')[0]
              const yourMerchant = e.response.statusText.split('|')[1]
              let composeError = this.$i18n.t('$l.error.mustBeSameMerchant')
              composeError = composeError.replace('$1', thisCodeMerchant)
              composeError = composeError.replace('$2', yourMerchant)
              this.callResult.error = composeError
            } else {
              this.callResult.error = e.response.localizedMessage
            }
          } else {
            this.callResult.error = e.response.localizedMessage
          }
          this.sendingRequest = false
        })
    }
  }
}
</script>
