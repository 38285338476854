<template>
  <div>
    <div
      class="greyee pa-3"
    >
      <basic-resp-row
        left="$l.overview.overPackageInvestigations"
        :right="`${overviewActivationData.overPackageInvestigations}`"
        :call-result="callResult"
        width="100%"
        rowcss="body-2 ma-0 mb-2 info1--text"
      />
      <basic-resp-row
        left="$l.overview.leftOverPackageInvestigations"
        :right="`${overviewActivationData.leftOverPackageInvestigations}`"
        :call-result="callResult"
        width="100%"
        :rowcss="overviewActivationData.leftOverPackageInvestigations > 5 ? 'body-2 ma-0 mb-2 success--text' : 'body-2 ma-0 mb-2 error--text'"
      />
      <basic-resp-row
        left="$l.services.priceOverPerInv"
        :right="$utils.formatCurrencyNoZero(overviewActivationData.activeProduct.priceOverPackageInvestigations/100)"
        :call-result="callResult"
        width="100%"
        rowcss="body-2 ma-0 mb-2 info1--text"
      />
      <basic-resp-row
        left="$l.services.subtractInvestigations"
        :right="calculateValue(overviewActivationData.subtractInvestigations)"
        :call-result="callResult"
        width="100%"
        rowcss="body-2 ma-0 mb-2 info1--text"
      />
      <basic-resp-row
        left="$l.services.enableInvestigationApi"
        :right="calculateValue(overviewActivationData.enableInvestigationApi)"
        :call-result="callResult"
        width="100%"
        rowcss="body-2 ma-0 mb-2 info1--text"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActivationInfoT2',
  props: {
    overviewActivationData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      callResult: { finished: false, authorized: true, error: null, info: null }
    }
  },
  created () {
    if (this.overviewActivationData.loadingTasksFinished === 3) {
      this.callResult.finished = true
    } else {
      setTimeout(() => {
        this.callResult.finished = true
      }, 4000)
    }
  },
  methods: {
    calculateValue (item) {
      if (item === true) {
        return this.$i18n.t('$l.app.yes').toUpperCase()
      }
      if (item === false) {
        return this.$i18n.t('$l.app.no').toUpperCase()
      }
      return '-'
    }
  }
}
</script>
