<template>
  <div>
    <v-row
      v-if="!callResult.finished || callResult.error"
      class="success2 white"
    >
      <v-col>
        <app-api-call
          class="ma-0 pa-0"
          :call-result="callResult"
        />
      </v-col>
    </v-row>

    <v-row
      no-gutters
    >
      <v-col>
        <v-alert
          v-if="overviewActivationData.suspended"
          dense
          class="d-flex justify-center text-center ma-0 pa-1 mb-2"
          outlined
          color="error"
        >
          <div
            v-if="overviewActivationData.suspendedInvestigations"
          >
            {{ '$l.services.suspendedInvestigations' | translate }}
          </div>
          <div
            v-if="overviewActivationData.suspendedActivation"
          >
            {{ '$l.services.suspendedActivation' | translate }}
          </div>
        </v-alert>
      </v-col>
    </v-row>

    <v-row
      dense
    >
      <v-col
        cols="12"
        md="6"
        xs="12"
      >
        <ActivationInfoT1
          :key="overviewActivationDataKey"
          :overview-activation-data="overviewActivationData"
          @reinit-overview-activation-data="init"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        xs="12"
      >
        <ActivationInfoT2
          :key="overviewActivationDataKey"
          :overview-activation-data="overviewActivationData"
        />
        <div
          class="d-flex justify-end"
        >
          <v-btn
            text
            class="primary ma-0 mt-2"
            @click="$emit('open-dialog', overviewActivationData)"
          >
            {{ $i18n.t('$l.overview.inputCoupon') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ActivationInfoT1 from './ActivationInfoT1.vue'
import ActivationInfoT2 from './ActivationInfoT2.vue'

export default {
  name: 'OverviewServices',
  components: {
    ActivationInfoT1,
    ActivationInfoT2
  },
  data () {
    return {
      callResult: { finished: true, authorized: true, error: null, info: null },
      overviewActivationData: { activeProduct: {} },
      overviewActivationDataKey: 0
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.callResult.finished = false
      this.$xapi.get('endpointsUserProfile/getActivationData')
        .then(r => {
          this.overviewActivationData = r.data
          if (!this.overviewActivationData.activeProduct) {
            this.overviewActivationData.activeProduct = {}
          }
          this.overviewActivationData.loadingTasksFinished = 1
          this.$xapi.post('endpointsUserProfile/hasRequiredRights', ['RIGHT_APP_DONT_SUBTRACT_INVESTIGATIONS'])
            .then(r => {
              this.overviewActivationData.subtractInvestigations = false
            })
            .catch(e => {
              this.overviewActivationData.subtractInvestigations = true
            })
            .finally(() => {
              this.overviewActivationData.loadingTasksFinished++
              this.overviewActivationDataKey = Date.now()
            })
          this.$xapi.post('endpointsUserProfile/hasRequiredRights', ['RIGHT_APP_ENABLE_PER_CLIENT_INVESTIGATION_API'])
            .then(r => {
              this.overviewActivationData.enableInvestigationApi = true
            })
            .catch(e => {
              this.overviewActivationData.enableInvestigationApi = false
            })
            .finally(() => {
              this.overviewActivationData.loadingTasksFinished++
              this.overviewActivationDataKey = Date.now()
            })
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.callResult.finished = true
        })
    },
    rethrowEmitToParent (activationData) {
      this.$emit('open-dialog', activationData)
    }
  }
}
</script>

<style>
  .adjust_slide_group .v-slide-group__prev {
    min-width: 0px;
    padding: 0px;
    flex: unset;
  }
</style>
