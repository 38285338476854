<template>
  <div>
    <div
      class="greyee pa-3"
    >
      <basic-resp-row
        left="$l.overview.activeProductName"
        :right="$i18n.t(`$l.product.${overviewActivationData.activeProduct.name}`)"
        :call-result="callResult"
        width="100%"
        rowcss="body-2 ma-0 mb-2 info1--text"
      />
      <basic-resp-row
        left="$l.overview.activeFrom"
        :right="overviewActivationData.activeProduct.activeFrom ? $utils.formatDate(overviewActivationData.activeProduct.activeFrom) : '0'"
        :call-result="callResult"
        width="100%"
        rowcss="body-2 ma-0 mb-2 info1--text"
      />
      <basic-resp-row
        left="$l.overview.activeTo"
        :right="overviewActivationData.activeProduct.activeTo ? $utils.formatDate(overviewActivationData.activeProduct.activeTo): '0'"
        :call-result="callResult"
        width="100%"
        rowcss="body-2 ma-0 mb-2 info1--text"
      />
      <span>
        <basic-resp-row
          v-if="overviewActivationData.activeProduct.name === 'FREEMIUM'"
          left="$l.overview.leftActiveToDefault"
          :right="getLeftDays(overviewActivationData.activeProduct.activeTo).toString()"
          :call-result="callResult"
          width="100%"
          :rowcss="getLeftDays(overviewActivationData.activeProduct.activeTo) > 5 ? 'body-2 ma-0 mb-2 success--text' : 'body-2 ma-0 mb-2 error--text'"
        />
        <basic-resp-row
          v-else
          left="$l.overview.leftActiveTo"
          :right="getLeftDays(overviewActivationData.activeProduct.activeTo).toString()"
          :call-result="callResult"
          width="100%"
          :rowcss="getLeftDays(overviewActivationData.activeProduct.activeTo) > 5 ? 'body-2 ma-0 mb-2 success--text' : 'body-2 ma-0 mb-2 error--text'"
        />
      </span>
      <basic-resp-row
        left="$l.overview.packageInvestigations"
        :right="`${overviewActivationData.activeProduct.packageInvestigations}`"
        :call-result="callResult"
        width="100%"
        rowcss="body-2 ma-0 mb-2 info1--text"
      />
      <basic-resp-row
        left="$l.overview.leftPackageInvestigations"
        :right="`${overviewActivationData.activeProduct.leftPackageInvestigations}`"
        :call-result="callResult"
        width="100%"
        :rowcss="overviewActivationData.activeProduct.leftPackageInvestigations > 5 ? 'body-2 ma-0 mb-2 success--text' : 'body-2 ma-0 mb-2 error--text'"
      />
      <basic-resp-row
        v-if="overviewActivationData.activeCouponId"
        left="$l.overview.activatedWithCoupon"
        :right="`${overviewActivationData.activeCouponId}`"
        :call-result="callResult"
        width="100%"
        rowcss="body-2 ma-0 mb-2 success--text"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActivationInfoT1',
  props: {
    overviewActivationData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      callResult: { finished: false, authorized: true, error: null, info: null },
      actint: {}
    }
  },
  created () {
    if (this.overviewActivationData.loadingTasksFinished === 3) {
      this.callResult.finished = true
    } else {
      setTimeout(() => {
        this.callResult.finished = true
      }, 4000)
    }
  },
  methods: {
    getLeftDays (to) {
      let daysLeft = to ? Math.floor((Date.parse(to) - Date.now()) / (24 * 60 * 60 * 1000)) : 0
      return daysLeft < 0 ? 0 : daysLeft
    }
  }
}
</script>
