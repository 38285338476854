<template>
  <div>
    <app-api-call
      class=""
      :call-result="callResult"
    />
    <v-carousel
      v-if="callResult.finished && !callResult.error"
      interval="8000"
      cycle
      height="auto"
      class="carousel"
      :show-arrows="false"
      progress
    >
      <v-carousel-item
        v-if="items.length==0"
      >
        <v-sheet
          color="greyee"
          height="100%"
        >
          <v-card-text
            class="justify-space-between align-center d-sm-flex"
          >
            <font color="D48D6A">
              {{ $i18n.t("$l.overview.newsNotAvailable") }}
            </font>
          </v-card-text>
        </v-sheet>
      </v-carousel-item>
      <v-carousel-item
        v-for="(item, i) in items"
        v-else
        :key="i"
      >
        <v-sheet
          color="greyee"
          height="100%"
        >
          <v-card-text
            class="justify-space-between align-center d-sm-flex"
          >
            <div class="title">
              <font color="#6f6f6f">
                {{ item.title }}
              </font>
            </div>
            <v-btn
              text
              class="primary mb-4 mt-4 ml-4 float-right"
              :href="item.link"
              :target="item.link"
            >
              {{ $i18n.t("$l.app.more") }}...
            </v-btn>
          </v-card-text>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
export default {
  name: 'NewsFlashOverview',
  data () {
    return {
      callResult: { finished: false, authorized: false, error: null, info: null },
      items: []
    }
  },
  watch: {
    '$i18n.locale' (newValue, oldValue) {
      this.init()
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.callResult.finished = false
      this.callResult.error = null
      this.items = []
      this.$xapi.get('overview/newsFlash')
        .then(r => {
          if (r.data) {
            var parser = new DOMParser()
            var xmlDoc = parser.parseFromString(r.data.xml, 'text/xml')
            for (var i = 0; i < r.data.messageCount; i++) {
              this.items.push({
                title: xmlDoc.getElementsByTagName('channel')[0].getElementsByTagName('item')[i].getElementsByTagName('title')[0].childNodes[0].nodeValue,
                link: xmlDoc.getElementsByTagName('channel')[0].getElementsByTagName('item')[i].getElementsByTagName('link')[0].childNodes[0].nodeValue,
                pubDate: xmlDoc.getElementsByTagName('channel')[0].getElementsByTagName('item')[i].getElementsByTagName('pubDate')[0].childNodes[0].nodeValue
              })
            }
          }
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.callResult.finished = true
          if (this.$i18n.locale !== 'sk') {
            this.items = []
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .carousel {
    border: 1px solid #ccc !important;
    border-radius: 0.3em;
    padding-bottom: 60px;
    .v-carousel__controls{
    background-color: #AAAAAA;
    }
    .v-card__text {
        padding: 8px;
    }
  }
</style>
